import Vuex from "vuex"
import cloneDeep from "clone-deep"
import { showFailedMessage, showSuccessedMessage } from "@/lib/showMessages"

import { firebaseService } from "@/services/firebase"
const initialState = {
  isAuthResolved: false, // ログイン状況の確認が済んだか
  user: {
    email: undefined,
    emailVerified: undefined,
    uid: undefined,
  }, // Authentication がもっているユーザー情報
  userProfile: {
    createdAt: undefined,
    updatedAt: undefined,
    fullName: undefined,
    isMentor: undefined,
    isPermitted: undefined,
    registeredCourseId: undefined,
    adminMemo: undefined,
    understandings: {},
    updatedBy: undefined,
    databaseId: undefined,
  }, // Firestore/users がもっているユーザー情報
}
const state = cloneDeep(initialState)

const mutations = {
  resolveAuth(state) {
    state.isAuthResolved = true
  },
  setUser(state, user) {
    if (user?.providerData?.length > 0) {
      state.user = {
        email: user.providerData[0].email,
        emailVerified: user.emailVerified,
        uid: user.uid,
      }
    } else {
      state.user = cloneDeep(initialState.user)
    }
  },
  setUserProfile(state, userProfile) {
    if (userProfile && userProfile.understandings) {
      state.userProfile = userProfile
    } else if (userProfile) {
      state.userProfile = userProfile
      // understanding は後から付け足したので、なければ初期化
      state.userProfile.understandings = {}
    } else {
      state.userProfile = cloneDeep(initialState.userProfile)
    }
  },
  setUserProfileUnderstandings(state, understanding) {
    state.userProfile.understandings = {
      ...state.userProfile.understandings,
      ...understanding,
    }
  },
}

const actions = {
  // ログイン状態が変化するときに呼び出す
  setUserAndProfile({ commit }, { user, userProfile }) {
    commit("resolveAuth")
    commit("setUser", user)
    commit("setUserProfile", userProfile)
  },
  // 教材にチェックを入れたときに呼び出す
  saveUnderstanding({ commit, state }, { id, understanding }) {
    commit("setUserProfileUnderstandings", { [id]: understanding })
    firebaseService
      .updateUserProfile(state.user.uid, state.userProfile)
      .catch((error) => {
        console.error(error)
        showFailedMessage("学習状況の保存に失敗しました。")
      })
  },
}

const getters = {
  user(state) {
    return {
      ...state.user,
      ...state.userProfile,
    }
  },
  userProfile(state) {
    return state.userProfile
  },
  understanding(state) {
    return (articleId) => {
      if (typeof state.userProfile.understandings !== "object") return undefined
      // eslint-disable-next-line no-prototype-builtins
      return state.userProfile.understandings.hasOwnProperty(articleId)
        ? state.userProfile.understandings[articleId]
        : undefined
    }
  },
  isMentor(state) {
    return state.user && state.userProfile?.isMentor
  },
  isSignedIn(state, getters) {
    return (
      getters.isAuthResolved &&
      getters.isSignedUp &&
      getters.isEmailVerified &&
      getters.isUserPermitted
    )
  },
  isUserPermitted(state) {
    return !!state.userProfile?.isPermitted
  },
  isEmailVerified(state) {
    return !!state.user?.emailVerified
  },
  isSignedUp(state) {
    return !!state.user?.uid
  },
  isAuthResolved(state) {
    return state.isAuthResolved
  },
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})

firebaseService.subscribeAuthState(({ user, userProfile }) => {
  store.dispatch("setUserAndProfile", { user, userProfile })
  if (state.user.uid) {
    showSuccessedMessage("ログインに成功しました。")
  }
})

export default store
