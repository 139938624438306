// @ts-check
// eslint-disable-next-line no-unused-vars
import { ElForm, ElMessageBox } from "element-plus"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { watchEffect, computed, ref, onMounted } from "vue"
import { firebaseService } from "@/services/firebase"
import { isMobile } from "@/lib/responsiveStyles"
import router from "@/router"
import store from "@/store"
import { showSuccessedMessage, showErrorMessage } from "@/lib/showMessages"
import { isAfterOrSameToday } from "./date-util"

// SPはフォームのラベルを上に、PCでは左に、表示する。
export const formLabelPosition = computed(() => (isMobile() ? "top" : "left"))

export const sendEmailVerification = async () => {
  try {
    if (!store.getters.isEmailVerified) {
      await firebaseService.sendEmailVerification()
      showSuccessedMessage("メールアドレスに認証メールを送りました 🔥 🚀 ")
    }
  } catch (error) {
    showErrorMessage(error)
  }
}

export const signOut = async () => {
  await ElMessageBox.confirm("ログアウトしますがよろしいですか？", "", {
    confirmButtonText: "ログアウトする",
    cancelButtonText: "やめる",
    type: "warning",
  }).then(async () => {
    await firebaseService.signOut()
    store.dispatch("setUserAndProfile", { user: null, userProfile: null })
    await router.push({ name: "LoginSignin" })
    showSuccessedMessage("ログアウトに成功しました。")
  })
}

// ログイン状態を取得したら、画面遷移する。signIn()と一緒に使う。
export const watchIsSignIn = () => {
  const store = useStore()
  const router = useRouter()
  const isSignedUp = computed(() => store.getters.isSignedUp)
  // サインイン状態になったら、Homeに遷移する
  watchEffect(() => {
    if (isSignedUp.value) {
      router.push({ name: "Home" })
    }
  })
}

export const signUpCourseOptions = ref([])

export const makeCourseOptions = async (option) => {
  const snapshot = await firebaseService.getCourses()

  const courses = snapshot.docs
    .map((course) => {
      return { ...course.data(), id: course.id }
    })
    .filter((course) =>
      // @ts-ignore
      option?.useFilter ? isAfterOrSameToday(course.startAt) : true
    )
    // @ts-ignore
    .sort((a, b) => a.term - b.term)
    .map((course) => ({
      value: course.id,
      // @ts-ignore
      label: course.title,
    }))

  courses.push({
    value: option?.useInForm ? "COURSE_ID_IS_NULL" : null,
    label: "その他",
  })

  return courses
}

export const validateCourseOptionsValue = (courseId) => {
  if (courseId === "COURSE_ID_IS_NULL") {
    return null
  }
  return courseId
}

export const mountCourseOptions = (ref, option) => {
  onMounted(async () => {
    const value = await makeCourseOptions(option)
    ref.value = value
  })
}
