<template>
  <BaseLayout>
    <ArticleChapter :chapterId="CHAPTER_ID.pre" class="ArticlesView__chapter" />

    <ArticleChapter
      :chapterId="CHAPTER_ID.setup"
      class="ArticlesView__chapter"
    />
    <ArticleChapter :chapterId="CHAPTER_ID.pc" class="ArticlesView__chapter" />

    <div class="ArticlesView__chapter ArticlesView__image-container">
      <img
        src="@/assets/images/ArticlesView/Javascript_Isometric.svg"
        alt="Isometric of JavaScript"
        class="ArticlesView__image"
      />
    </div>

    <ArticleChapter
      :chapterId="CHAPTER_ID.js_basic"
      class="ArticlesView__chapter"
    />
    <ArticleChapter
      :chapterId="CHAPTER_ID.js_dom"
      class="ArticlesView__chapter"
    />

    <div class="ArticlesView__chapter ArticlesView__image-container">
      <img
        src="@/assets/images/ArticlesView/Content_creation_Isometric.svg"
        alt="Isometric of Content creation"
        class="ArticlesView__image"
      />
    </div>

    <ArticleChapter
      :chapterId="CHAPTER_ID.recipe1"
      class="ArticlesView__chapter"
    />
    <ArticleChapter
      :chapterId="CHAPTER_ID.programming"
      class="ArticlesView__chapter"
    />
    <ArticleChapter
      :chapterId="CHAPTER_ID.recipe2"
      class="ArticlesView__chapter"
    />

    <div class="ArticlesView__chapter ArticlesView__image-container">
      <img
        src="@/assets/images/ArticlesView/Designer_Isometric.svg"
        alt="Isometric of Content creation"
        class="ArticlesView__image"
      />
    </div>

    <ArticleChapter
      :chapterId="CHAPTER_ID.web_design"
      class="ArticlesView__chapter"
    />

    <div class="ArticlesView__chapter ArticlesView__image-container">
      <img
        src="@/assets/images/ArticlesView/Twitter_Flatline.svg"
        alt="Isometric of Content creation"
        class="ArticlesView__image"
      />
    </div>

    <ArticleChapter
      :chapterId="CHAPTER_ID.react"
      class="ArticlesView__chapter"
    />
    <ArticleChapter :chapterId="CHAPTER_ID.git" class="ArticlesView__chapter" />
    <ArticleChapter
      :chapterId="CHAPTER_ID.geek_twitter_with_react"
      class="ArticlesView__chapter"
    />
    <ArticleChapter
      :chapterId="CHAPTER_ID.firebase"
      class="ArticlesView__chapter"
    />
    <ArticleChapter
      :chapterId="CHAPTER_ID.team_development"
      class="ArticlesView__chapter"
    />
  </BaseLayout>
</template>

<script>
import { CHAPTER_ID } from "@/lib/chapter"

import BaseLayout from "@/components/BaseLayout"
import ArticleChapter from "@/components/article/ArticleChapter"

export default {
  components: {
    BaseLayout,
    ArticleChapter,
  },
  setup() {
    return { CHAPTER_ID }
  },
}
</script>

<style lang="scss" scoped>
.ArticlesView__chapter {
  margin-top: 2rem;
}
.ArticlesView__image-container {
  display: flex;
  justify-content: center;
}
.ArticlesView__image {
  height: 150px;
  @include sp() {
    height: 100px;
  }
}
</style>
