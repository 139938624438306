import { defineComponent, shallowRef, reactive, watch } from "vue"
import { useRoute } from "vue-router"

import ArticleLink from "@/components/article/ArticleLink"
import { getMarkdownById } from "@/lib/markdown"

// state
export const article = reactive({
  id: null,
  attributes: {
    title: null,
    time: null,
    category: null,
  },
  component: null,
  headings: [
    // { id: "%E3%81%AF%E3%81%98%E3%82%81%E3%81%AB", title: "はじめに", level: 1 },
  ],
  headingsFixed: false,
})

const changeArticle = async (id) => {
  const newArticle = await getArticle(id).catch(async () => {
    await changeArticle("404")
  })
  Object.assign(article, newArticle)
}

const articlesCache = {}
const getArticle = async (id) => {
  if (articlesCache[id]) {
    return articlesCache[id]
  }
  const markdown = getMarkdownById(id)
  const { title, type, time, asyncImport } = markdown

  const template = (await asyncImport()).default
  const attributes = {
    title,
    type,
    time,
  }

  const component = defineComponent({
    name: "ArticleComponent",
    template,
    created() {
      this.attributes = attributes
    },
    mounted() {
      // 現在の記事の目次を固定
      articlesCache[id].headingsFixed = true
    },
    components: {
      ArticleLink,
    },
  })

  const article = {
    id,
    attributes,
    component: shallowRef(component),
    headings: [],
    headingsFixed: false,
  }
  articlesCache[id] = article

  return article
}

// 目次を登録する
// ArticleHeading から呼び出す
export const pushHeading = (article, { id, title, level }) => {
  const articleCache = articlesCache[article.id]
  if (!articleCache) {
    throw new Error(`article ${article.id} is not initialized`)
  }
  if (articleCache.headingsFixed) {
    return
  }
  if (typeof id !== "string") throw new TypeError("id must be a string")
  if (typeof title !== "string") throw new TypeError("title must be a string")
  if (typeof level !== "number") throw new TypeError("lelve must be a number")

  articleCache.headings.push({ id, title, level })
}

// ルートの変更を監視して適切な article に切り替える
// ArticleShow から呼び出す
export const watchArticleRoute = () => {
  const route = useRoute()

  const onChangeRoute = async (to) => {
    const name = to.name
    const id = to.params.articleId

    const isNotArticle = name !== "Article"
    if (isNotArticle) {
      return
    }

    await changeArticle(id)

    if (!route.hash) {
      document.scrollingElement.scrollTop = 0
    } else {
      try {
        const target = document.getElementById(route.hash.substring(1))
        if (target) {
          window.scroll(0, target.offsetTop)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  watch(() => route, onChangeRoute, {
    immediate: true,
    deep: true,
  })

  watch(article, (newArticle) => {
    document.title = newArticle.attributes.title
      ? `${newArticle.attributes.title} | WebExpertコース教材システム`
      : "WebExpertコース教材システム"
  })
}
