import { ADMIN_MEMO_TEMPLATE } from "@/lib/const"

export const validatedUserProfile = (profile) => {
  const { fullName, registeredCourseId, isMentor, updatedBy } = profile

  const isValidFullName = !!fullName && typeof fullName === "string"
  const isValidRegisteredCourseId =
    registeredCourseId === null || typeof registeredCourseId === "string"
  const isValidIsMentor = typeof isMentor === "boolean"
  const isValidUpdatedBy = typeof updatedBy === "string"

  if (!isValidFullName) {
    throw new TypeError("Invalid fullName value")
  }

  if (!isValidRegisteredCourseId) {
    throw new TypeError("Invalid registeredCourseId value")
  }

  if (!isValidIsMentor) {
    throw new TypeError("Invalid isMentor value")
  }

  if (!isValidUpdatedBy) {
    throw new TypeError("Invalid updatedBy value")
  }

  return profile
}
export const makeUserProfile = (profile) => {
  return {
    ...validatedUserProfile(profile),
    isPermitted: false,
    understandings: {},
    adminMemo: ADMIN_MEMO_TEMPLATE,
    databaseId: "",
  }
}
