<template>
  <div class="BaseLayout__outer">
    <div class="BaseLayout__container">
      <div class="BaseLayout__content">
        <slot></slot>
      </div>
      <aside class="BaseLayout__aside">
        <slot name="aside"></slot>
      </aside>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.BaseLayout__outer {
  box-sizing: border-box;
  padding: 80px 0 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
.BaseLayout__container {
  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: 1280px;
  padding: 1rem;
  background: var(--background);
}
.BaseLayout__content {
  width: 70vw;
  max-width: 880px;
  overflow: auto;
  filter: drop-shadow(0 1px 2px var(--light-gray));
  @include tablet() {
    width: 100%;
  }
}
.BaseLayout__aside {
  margin-left: 20px;
  @include tablet() {
    display: none;
  }
}
</style>
