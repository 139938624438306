import { nextTick } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import HomeView from "@/views/HomeView.vue"
import LoginView from "@/views/LoginView.vue"
import ArticleShow from "@/views/ArticleShow.vue"
import AdminView from "@/views/AdminView.vue"
import ArticlesView from "@/views/ArticlesView.vue"
import TheSignIn from "@/views/Login/TheSignIn.vue"
import TheSignUp from "@/views/Login/TheSignUp.vue"
import TheRequiredAddressComfirm from "@/views/Login/TheRequiredAddressComfirm.vue"
import TheRequiredPermission from "@/views/Login/TheRequiredPermission.vue"
import ThePassWordReset from "@/views/Login/ThePassWordReset.vue"
import store from "@/store/index.js"
import { firebaseService } from "./services/firebase"

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: "WebExpertコース教材システム",
    },
  },
  {
    path: "/articles",
    name: "Articles",
    component: ArticlesView,
    meta: {
      requiresAuth: true,
      title: "記事一覧 | WebExpertコース教材システム",
    },
  },
  {
    path: "/article/:articleId?",
    name: "Article",
    component: ArticleShow,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
      title: "admin | WebExpertコース教材システム",
    },
  },
  {
    path: "/login",
    component: LoginView,
    children: [
      {
        path: "",
        name: "LoginSignin",
        component: TheSignIn,
        meta: {
          requiresSignedOut: true,
        },
      },
      {
        path: "signup",
        name: "LoginSignup",
        component: TheSignUp,
        meta: {
          requiresSignedOut: true,
        },
      },
      {
        path: "reset",
        name: "LoginResetPassword",
        component: ThePassWordReset,
        meta: {
          requiresSignedOut: true,
        },
      },
      {
        path: "confirmed",
        name: "LoginConfirmed",
        component: TheRequiredAddressComfirm,
        meta: {
          requiresAuth: true,
          requiresNotCompleteSignIn: true,
        },
      },
      {
        path: "verified",
        name: "LoginEmailVerified",
        component: TheRequiredPermission,
        meta: {
          requiresAuth: true,
          requiresNotCompleteSignIn: true,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

// ナビゲーションガード時に使う。firebaseServiceに定義すると、storeが循環するのでここに書いている
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    try {
      if (store.getters.isAuthResolved) {
        resolve(store.getters.user)
      } else {
        firebaseService
          .subscribeAuthState(({ user, userProfile }) => {
            store.dispatch("setUserAndProfile", { user, userProfile })
            resolve(store.getters.user)
          })
          .then((unsubscribe) => unsubscribe())
      }
    } catch (error) {
      reject(error)
    }
  })
}

router.beforeEach(async (to) => {
  // Authが解決していなかったら、解決まで待機する
  await getCurrentUser()

  // 該当するルートがない
  if (to.matched.length === 0)
    return { name: "Home", query: { redirect: to.fullPath } }

  // Admin 権限がない
  if (to.meta.requiresAdminAuth && !store.getters.isMentor)
    return { name: "Home", query: { redirect: to.fullPath } }

  // ログイン後、ログインページに遷移しようとした場合
  if (to.meta.requiresSignedOut && store.getters.isSignedIn)
    return { name: "Home", query: { redirect: to.fullPath } }

  // ログイン&認証 していたら、ログイン周りの画面にはアクセスできない
  if (to.meta.requiresNotCompleteSignIn && store.getters.isSignedIn)
    return { name: "Home", query: { redirect: to.fullPath } }

  // ログインしているが、未認証
  if (
    (to.meta.requiresAuth || to.meta.requiresSignedOut) &&
    store.getters.isSignedUp &&
    !store.getters.isSignedIn
  ) {
    // メールアドレス承認待ち
    if (!store.getters.isEmailVerified && to.name !== "LoginConfirmed")
      return { name: "LoginConfirmed" }
    // Permission 承認待ち
    if (store.getters.isEmailVerified && to.name !== "LoginEmailVerified")
      return { name: "LoginEmailVerified" }
  }

  // ログインしていない場合、ログインページに遷移
  if (
    to.meta.requiresAuth &&
    !(store.getters.isSignedIn || store.getters.isSignedUp) &&
    to.name !== "LoginSignin"
  )
    return { name: "LoginSignin" }

  // 正常なルート
  return true
})

router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ?? "WebExpertコース教材システム"
  })
})

export default router
