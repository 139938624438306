import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/functions"

const firebaseConfig = {
  apiKey: "AIzaSyB81LeezthJZHCLrVf7tg4jwggm4lPrua4",
  authDomain: "exsys-a1f12.firebaseapp.com",
  databaseURL: "https://exsys-a1f12.firebaseio.com",
  projectId: "exsys-a1f12",
  storageBucket: "exsys-a1f12.appspot.com",
  messagingSenderId: "485121662462",
  appId: "1:485121662462:web:c1567d2dddc1f6059a34af",
  measurementId: "G-GEHD5QB35M",
}

const app = firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

if (
  location.hostname === "localhost" &&
  process.env.VUE_APP_USE_EMULATOR === "true"
) {
  firebase.auth().useEmulator("http://localhost:9099")
  firebase.firestore().useEmulator("localhost", 9000)
  firebase.functions().useEmulator("localhost", 5001)
}

export default app
