<template>
  <el-row :gutter="20" class="AdminManageUser__info-container">
    <el-col>
      <el-row :gutter="20" justify="start">
        <el-col :xs="24" :md="8">
          <el-button-group>
            <el-button disabled>{{ filteredUsers.length }}</el-button>
            <el-button @click="get50UsersForAdmin(users.length)"
              >50件追加</el-button
            >
            <el-button @click="getAllUsersForAdmin">全取得</el-button>
          </el-button-group>
        </el-col>
        <el-col :xs="0" :md="8"> </el-col>
        <el-col :xs="24" :sm="12" :md="8">
          <el-input
            v-model="userFilterInput"
            placeholder="名前を入力してください。"
          >
          </el-input>
        </el-col>
      </el-row>
    </el-col>
    <el-col>
      <el-table
        :data="filteredUsers"
        :v-loading="isUsersLoading"
        border
        fit
        stripe
        highlight-current-row
        table-layout="auto"
        :default-sort="{ prop: 'updatedAt', order: 'descending' }"
        :row-class-name="tableRowStatusClass"
        @expand-change="onExpandRow"
        class="AdminManageUser__table"
      >
        <AdminMnageTableExpandColumn />
        <el-table-column align="center" label="uid" prop="uid" width="270" />
        <el-table-column
          align="center"
          label="fullName"
          prop="fullName"
          width="200"
        >
          <template #default="{ row }">
            <el-input border v-model="row.fullName" v-if="isEdit(row.uid)">
              {{ row.fullName }}
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="registeredCourseId"
          prop="registeredCourseId"
          width="200"
          sortable
          :sort-by="(row) => row.registeredCourseId"
        >
          <template #default="{ row }">
            <el-select
              border
              v-model="row.registeredCourseId"
              v-if="isEdit(row.uid)"
            >
              <el-option
                v-for="course in courseOptions"
                :key="course.value"
                :label="course.label"
                :value="course.value"
              />
            </el-select>
            <el-tag
              v-else
              :type="row.registeredCourseId ? 'success' : 'info'"
              effect="light"
            >
              {{ getCourseLabel(row.registeredCourseId).value }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="isMentor"
          prop="isMentor"
          width="140"
          sortable
          :sort-by="(row) => row.isMentor"
          :sort-method="(bool) => (bool ? 1 : -1)"
        >
          <template #default="{ row }">
            <el-checkbox border v-model="row.isMentor" v-if="isEdit(row.uid)">
              {{ row.isMentor }}
            </el-checkbox>
            <el-tag :type="row.isMentor ? 'success' : 'danger'" v-else>{{
              row.isMentor
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="isPermitted"
          prop="isPermitted"
          width="140"
          sortable
          :sort-by="(row) => row.isPermitted"
          :sort-method="(bool) => (bool ? 1 : -1)"
        >
          <template #default="{ row }">
            <el-checkbox
              border
              v-model="row.isPermitted"
              v-if="isEdit(row.uid)"
            >
              {{ row.isPermitted }}
            </el-checkbox>
            <el-tag
              :type="row.isPermitted ? 'success' : 'danger'"
              :effect="row.isPermitted ? 'light' : 'dark'"
              v-else
              >{{ row.isPermitted }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="understandings"
          prop="understandings"
          width="140"
        >
          <template #default="{ row }">
            <el-row justify="space-around">
              <el-tag type="success">
                {{ countUnderstandings(row.understandings, "good") }}
              </el-tag>
              <el-tag type="warning">
                {{ countUnderstandings(row.understandings, "poor") }}
              </el-tag>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="databaseId"
          prop="databaseId"
          width="100"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <el-input border v-model="row.databaseId" v-if="isEdit(row.uid)">
              {{ row.databaseId }}
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="createdAt"
          prop="createdAt"
          :width="160"
          sortable
          :sort-by="(row) => row.createdAt.seconds"
        >
          <template #default="{ row }">{{
            formatDate(row.createdAt.toDate())
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          label="updatedAt"
          prop="updatedAt"
          :width="160"
          sortable
          :sort-by="(row) => row.updatedAt.seconds"
        >
          <template #default="{ row }">{{
            formatDate(row.updatedAt.toDate())
          }}</template>
        </el-table-column>
        <AdimnManageTableColumnEdit />
      </el-table>
    </el-col>
  </el-row>
</template>

<script setup>
import { computed, ref } from "vue"
import {
  getAllUsersForAdmin,
  isUsersLoading,
  users,
  formatDate,
  isEdit,
  courseOptions,
  mountCourseOptions,
  getCourseLabel,
  onExpandRow,
  registerUnsubscribeAllUser,
} from "@/lib/admin"
import AdimnManageTableColumnEdit from "@/components/admin/AdimnManageTableColumnEdit.vue"
import AdminMnageTableExpandColumn from "@/components/admin/AdminManageTableExpandColumn.vue"

mountCourseOptions(courseOptions)
registerUnsubscribeAllUser()

const tableRowStatusClass = ({ row }) => {
  if (row.isPermitted === false) return "danger-row"
  return ""
}

const userFilterInput = ref("")
const filteredUsers = computed(() => {
  if (users.value.length === 0 || !userFilterInput.value) {
    return users.value
  }

  return users.value.filter((user) =>
    user.fullName.includes(userFilterInput.value)
  )
})

const countUnderstandings = computed(() => (understandings, understanding) => {
  if (understandings === undefined) return 0
  return Object.values(understandings).filter(
    (value) => value === understanding
  ).length
})
</script>

<style lang="scss" scoped>
// TODO このスタイルを当てる
.danger-row {
  --el-table-tr-background-color: var(--el-color-danger-lighter);
}

.AdminManageUser__info-container {
  gap: 20px;
}

.AdminManageUser__table {
  min-height: calc(90vh - 4rem);
}

.AdminMangaeUser__inner-table {
  padding-left: 3rem;
}
</style>
