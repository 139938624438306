<!-- eslint-disable no-irregular-whitespace -->
<template>
  <router-link
    :to="{ name: 'Article', params: { articleId: markdown.id } }"
    custom
    v-slot="{ navigate }"
  >
    <div
      class="ArticleChapter__li-container"
      @click="navigate"
      @keypress.enter="navigate"
      role="link"
      tabindex="1"
    >
      <span class="ArticleChapter__li" :data-type="markdown.type">
        <span class="ArticleChapter__link ArticleChapter__icons">
          <span
            v-show="getUnderstanding(markdown.id) === 'good'"
            class="ArticleChapter__link--icon"
            >✅</span
          >
          <span
            v-show="getUnderstanding(markdown.id) === 'poor'"
            class="ArticleChapter__link--icon"
            >☑️</span
          >
          <span
            v-show="
              !(
                getUnderstanding(markdown.id) === 'poor' ||
                getUnderstanding(markdown.id) === 'good'
              )
            "
            class="ArticleChapter__link--icon"
            >　</span
          >
        </span>
        <span>【{{ typeText }}】</span>
        <span class="ArticleChapter__link ArticleChapter__link--text">
          {{ markdown.title }}
        </span>
        <span v-if="markdown.time" class="ArticleChapter__link--timer"
          >{{ markdown.time }} 分</span
        >
      </span>
    </div>
  </router-link>
</template>

<script setup>
import { computed } from "vue"
import { getUnderstanding } from "@/lib/understandings"
import { getMarkdownById } from "@/lib/markdown"
const props = defineProps({
  articleId: String,
})
const markdown = computed(() => getMarkdownById(props.articleId))
const typeText = computed(() => {
  switch (markdown.value.type) {
    case "recipe": {
      return "レシピ"
    }
    case "text": {
      return "記事"
    }
    default: {
      return "その他"
    }
  }
})
</script>

<style lang="scss" scoped>
.ArticleChapter__li-container {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid var(--border);
  background: var(--white);
  transition: background ease-out 0.15s;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background: var(--background);
  }
}
.ArticleChapter__li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.5;
  list-style-position: inside;
  display: inline-flex;
  gap: 0.25em;
}
.ArticleChapter__link {
  letter-spacing: 0.125rem;
}
.ArticleChapter__icons {
  width: fit-content;
  display: inline-flex;
  gap: 0.25rem;
}
.ArticleChapter__link--text {
  color: var(--text-dark);
}
.ArticleChapter__link--timer {
  min-width: 3.5rem;
  line-height: 1.4rem;
  text-align: center;
  border-radius: 0.4rem;
  border: 1px solid var(--primary);
  font-weight: 600;
}
</style>
