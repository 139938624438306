<template>
  <el-table-column type="expand">
    <template #default="{ row }">
      <el-row :gutter="20">
        <el-col :offset="1" :span="6">
          <h2>understandings</h2>
          <el-table
            :data="getUnderstandingsTableData(row.understandings)"
            border
            fit
            stripe
          >
            <el-table-column label="教材名" prop="id" />
            <el-table-column label="理解度" prop="value">
              <template #default="{ row: understading }">
                <el-tag :type="understandingTagType(understading.value)">
                  {{ understading.value }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="10">
          <h2>Notion Page</h2>
          <AdminNotionMemo
            :database-id="row.databaseId"
            :fullName="row.fullName"
          />
        </el-col>
        <el-col :span="6">
          <h2 class="center-inline" @click="isOpenEdit = !isOpenEdit">
            <el-icon :size="30"><Edit /></el-icon>
            Admin memo edit
          </h2>
          <FadeInOut entry="center" exit="top" :duration="250" mode="out-in">
            <div v-show="isOpenEdit">
              <AdminUserMemo :uid="row.uid" />
            </div>
          </FadeInOut>
        </el-col>
      </el-row>
    </template>
  </el-table-column>
</template>

<script setup>
import { computed, ref } from "vue"
import { getAllArticleId } from "@/lib/admin"
import AdminUserMemo from "@/components/admin/AdminUserMemo.vue"
import AdminNotionMemo from "@/components/admin/AdminNotionMemo.vue"

import { Edit } from "@element-plus/icons-vue"
import { FadeInOut } from "vue3-transitions"

const isOpenEdit = ref(true)

const understandingTagType = computed(() => (understanding) => {
  if (understanding === "good") return "success"
  if (understanding === "poor") return "warning"
  return ""
})

const getUnderstandingsTableData = computed(() => (understandings) => {
  if (understandings === undefined) return []
  const allArticleId = getAllArticleId()
  const initialUnderstandings = {}
  allArticleId.forEach((id) => {
    initialUnderstandings[id] = ""
  })
  const allUnderstandings = { ...initialUnderstandings, ...understandings }
  return Object.entries(allUnderstandings).map(([id, value]) => {
    return { id, value }
  })
})
</script>

<style scoped>
.center-inline {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}
</style>
