<template>
  <section class="ScheduleRow__container">
    <div class="ScheduleRow__line" :data-color="color" v-if="!end"></div>
    <div class="ScheduleRow__dot" :data-color="color"></div>
    <div class="ScheduleRow__content">
      <h2 class="ScheduleRow__title" :data-color="color">{{ title }}</h2>
      <div class="ScheduleRow__description">
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    color: {
      type: String,
    },
    end: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.ScheduleRow__container {
  display: flex;
}
.ScheduleRow__line {
  position: relative;
  z-index: 1;
  top: calc(1.5rem / 2);
  left: 0.75rem;
  transform: translateX(1px);
  width: 2px;
  border-left: 2px dotted var(--primary-light);
}
.ScheduleRow__dot {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  &:before {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: var(--primary);
  }
  &[data-color="black"]:before {
    background: var(--text-dark);
  }
  &:after {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: var(--white);
  }
}
.ScheduleRow__content {
  flex: 1;
  margin-left: 1.5rem;
}
.ScheduleRow__title {
  font-size: 1.5rem;
  margin: 0;
  line-height: 1;
  color: var(--primary);
}
.ScheduleRow__description {
  padding: 1rem 0 2rem;
}

[data-color="black"] {
  &.ScheduleRow__line {
    border-left: 2px dotted var(--text-dark);
  }
  &.ScheduleRow__dot:before {
    background: var(--text-dark);
  }
  &.ScheduleRow__title {
    color: var(--text-dark);
  }
}
</style>
