<template>
  <el-container direction="vertical" class="AdminBaseLayout__container">
    <el-header width="150px" class="AdminBaseLayout__navigation"
      ><slot name="navigation"
    /></el-header>
    <el-main class="main"><slot name="main" /></el-main>
  </el-container>
</template>

<style lang="scss" scoped>
.AdminBaseLayout__container {
  margin-top: 4rem;
}

.AdminBaseLayout__navigation {
  background-color: var(--el-color-white);
}
</style>
