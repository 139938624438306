import { ref } from "vue"
import { Markdown } from "@/lib/markdown"

export const CHAPTER_ID = {
  pre: "pre",
  html_css: "html_css",
  setup: "setup",
  pc: "pc",
  js_basic: "js_basic",
  js_dom: "js_dom",
  recipe1: "recipe1",
  programming: "programming",
  recipe2: "recipe2",
  vue_pre: "vue_pre",
  vue_cli: "vue_cli",
  react: "react",
  git: "git",
  geek_twitter: "geek_twitter",
  geek_twitter_with_react: "geek_twitter_with_react",
  firebase: "firebase",
  team_development: "team_development",
  web_design: "web_design",
}

export const chapters = ref(new Map())

export class Chapter {
  constructor({ id, title, type, markdowns }) {
    if (chapters.value.get(id)) {
      throw new Error("duplicated chapter id " + id)
    }
    chapters.value.set(id, this)

    this.id = id
    this.title = title
    this.type = type
    this.markdowns = markdowns.map((markdown) => {
      return new Markdown(markdown)
    })
  }
}
