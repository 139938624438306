<template>
  <router-link :to="{ name: 'Article', params: { articleId: markdown.id } }"
    ><div class="article-link" :data-type="markdown.type">
      {{ markdown.title }}
    </div></router-link
  >
</template>

<script>
import { defineComponent } from "vue"

import { getMarkdownById } from "@/lib/markdown"

export default defineComponent({
  props: ["to"],
  setup(props) {
    let markdown = getMarkdownById(props.to)
    return { markdown }
  },
})
</script>

<style lang="scss" scoped>
.article-link {
  margin: 0.25em 1em;
  padding: 0.5em;
  cursor: pointer;
  color: #333;
  border-radius: 2px;

  &[data-type="text"] {
    border: 1px solid #f4a500;
    background-color: rgba($color: #f4a500, $alpha: 0.1);
    &:hover {
      background-color: rgba($color: #f4a500, $alpha: 0.2);
      &:before {
        content: "📖";
      }
    }
    &:before {
      content: "📘";
      margin-right: 0.5em;
    }
  }

  &[data-type="recipe"] {
    border: 1px solid #54c500;
    background-color: rgba($color: #54c500, $alpha: 0.1);
    &:hover {
      background-color: rgba($color: #54c500, $alpha: 0.2);
      &:before {
        content: "🚀";
      }
    }
    &:before {
      content: "🌏";
      margin-right: 0.5em;
    }
  }
}
</style>
