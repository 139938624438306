<template>
  <nav class="login-nav">
    <el-space direction="vertical" size="small">
      <router-link
        to="/login"
        v-slot="{ href, navigate }"
        class="login-nav__link"
      >
        <el-link
          :href="href"
          :disabled="$route.path === '/login'"
          @click="navigate"
        >
          既にアカウントをお持ちの方はこちら</el-link
        ></router-link
      >
      <router-link
        to="/login/signup"
        v-slot="{ href, navigate }"
        class="login-nav__link"
      >
        <el-link
          :href="href"
          :disabled="$route.path === '/login/signup'"
          @click="navigate"
        >
          アカウントをまだお持ちでない方はこちら</el-link
        ></router-link
      >
      <router-link
        to="/login/reset"
        v-slot="{ href, navigate }"
        class="login-nav__link"
      >
        <el-link
          :href="href"
          :disabled="$route.path === '/login/reset'"
          @click="navigate"
        >
          パスワードを忘れた方はこちら</el-link
        ></router-link
      >
    </el-space>
  </nav>
</template>

<style lang="scss" scoped>
.login-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;

  &__link {
    font-size: 0.8em;
    text-decoration: none;
  }
}
</style>
