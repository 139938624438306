<template>
  <div>
    <h1 class="ArticleUnderstandingButtons__heading">
      教材を終えたら理解度を設定しよう！
    </h1>
    <ol class="ArticleUnderstandingButtons__container">
      <li class="ArticleUnderstandingButtons__list-item">
        <button
          class="ArticleUnderstandingButtons__button"
          :class="{
            'ArticleUnderstandingButtons__button--checked':
              articleUnderstanding.understanding === 'good',
          }"
          @click="setUnderstanding('good')"
        >
          <div
            class="ArticleUnderstandingButtons__button-icon"
            :class="{
              'ArticleUnderstandingButtons__button--animated':
                animationClassHook === 'good',
            }"
          >
            🙆
          </div>
          <div
            class="ArticleUnderstandingButtons__button-text"
            :class="{
              'ArticleUnderstandingButtons__button--animated':
                animationClassHook === 'good',
            }"
          >
            自信あり
          </div>
        </button>
      </li>
      <li class="ArticleUnderstandingButtons__list-item">
        <button
          class="ArticleUnderstandingButtons__button"
          :class="{
            'ArticleUnderstandingButtons__button--checked':
              articleUnderstanding.understanding === 'poor',
          }"
          @click="setUnderstanding('poor')"
        >
          <div
            class="ArticleUnderstandingButtons__button-icon"
            :class="{
              'ArticleUnderstandingButtons__button--animated':
                animationClassHook === 'poor',
            }"
          >
            🤷
          </div>
          <div
            class="ArticleUnderstandingButtons__button-text"
            :class="{
              'ArticleUnderstandingButtons__button--animated':
                animationClassHook === 'poor',
            }"
          >
            自信なし
          </div>
        </button>
      </li>
    </ol>
  </div>
</template>

<script>
import { ref } from "vue"
import { useStore } from "vuex"
import { articleUnderstading } from "@/lib/understandings"

export default {
  setup() {
    const store = useStore()

    const articleUnderstanding = articleUnderstading

    const animationClassHook = ref("")

    const setUnderstanding = (understanding) => {
      if (articleUnderstanding.value.understanding !== understanding) {
        store
          .dispatch("saveUnderstanding", {
            id: articleUnderstanding.value.id,
            understanding: understanding,
          })
          .then(() => {
            animationClassHook.value = understanding
            setTimeout(() => {
              animationClassHook.value = ""
            }, 500)
          })
      }
    }

    return {
      setUnderstanding,
      articleUnderstanding,
      animationClassHook,
    }
  },
}
</script>

<style lang="scss" scoped>
.ArticleUnderstandingButtons__heading {
  background: linear-gradient(transparent 90%, var(--primary-light) 90%);
}

.ArticleUnderstandingButtons__container {
  padding: 0;
  display: flex;
  justify-content: space-around;
  @include sp {
    flex-direction: column;
  }
}

.ArticleUnderstandingButtons__list-item {
  list-style: none;
}

.ArticleUnderstandingButtons__button {
  // reset css
  appearance: none;
  border: 0;

  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 18rem;
  min-height: 5rem;
  width: 100%;
  padding: 0.5rem 0;
  background-color: var(--primary);
  border-radius: 1rem;
  opacity: 0.5;

  @include sp {
    padding: 0;
    margin-top: 1rem;
  }

  &:hover {
    background-color: var(--primary-dark);
    opacity: 1;
  }
}

.ArticleUnderstandingButtons__button--checked {
  background-color: var(--primary-dark);
  opacity: 1;
}

.ArticleUnderstandingButtons__button--animated {
  animation-name: bounce-in;
  animation-duration: 500ms;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.ArticleUnderstandingButtons__button-icon {
  font-size: 2em;
}

.ArticleUnderstandingButtons__button-icon--large {
  font-size: 2.5em;
}

.ArticleUnderstandingButtons__button-text {
  font-size: 1em;
  font-weight: bold;
}
</style>
