export const REGIONS = Object.freeze({
  TOKYO: "東京",
  KYOTO: "京都",
  NAGOYA: "名古屋",
  FUKUOKA: "福岡",
  ONLINE: "オンライン",
})
export const COURSES = Object.freeze({
  WEB: "Web",
  GAME: "Game",
  IPHONE: "iPhone",
  VIDEOEDITOR: "VideoEditor",
  UIUX: "UI/UX",
})
export const ADMIN_MEMO_TEMPLATE = `## Week 1

## Week 2

## Week 3

## Week 4

## Week 5

## Week 6

## Week 7

## Week 8

## Team
`

export const AGREEMENTS_SENTENCE = `●キャンセルについて（Pythonコースは除く）
入会申し込みが完了した日から起算して8日以内はクーリング・オフ制度を利用して契約を解除することができます。
入会申し込みが完了した日から起算して９日目以降は次に定める違約金が発生します。

●中途解約について（Pythonコースは除く）
入会申し込みが完了した日から起算して9日目以降は以下の通りに違約金が発生します。
受講料を支払われていない場合は、下記記載の金額を速やかにお振込お願いいたします。
すでに受講料を支払われている場合は、違約金と提供されたサービスの対価に相当する金額の合計額を受講料から差し引き、解約申し出があった日の翌月末日までに返金致します。

・申し込みコースの教材配布前
  1万5000円

・申し込みコースの教材配布後/開講日前日まで
  受講料（税込）の総額の1/3に相当する額を教材費として発生します。
  加えて、受講料（税込）の総額から教材費に相当する額を控除した額の20％を違約金として頂戴いたします。すなわち支払いを申し付ける金額は以下の通りとなります。
  【受講料（税込）】×1/3+【受講料（税込）】×(1-1/3)×0.2

・申し込みコースの開講日以降
  受講料（税込）の総額の1/3に相当する額を教材費として発生します。
  加えて、受講料（税込）の総額から教材費に相当する額を控除した額から開講後日数消化分をコース運営日数から日割りで計算した額をすでに提供された授業対価として発生します。
  加えて、受講料（税込）の総額から教材費及び、授業対価に相当する額を控除した額の20％を違約金として頂戴いたします。すなわち支払いを申し付ける金額は以下の通りとなります。
  【受講料（税込）】×1/3+【受講料（税込）】×(1-1/3)×【コース運営日数のうち日数消化率（%）】+【受講料（税込）】×{1-1/3-(1-1/3)×【コース運営日数のうち日数消化率（%）】}×0.2

●コース変更規定
受講コースの変更は、入会申込み完了日より8日以内かつ開講前日かつ教材配布前まで１回のみ可能です。
入会申し込み完了日より8日を過ぎてのコース変更、もしくは開講後もしくは教材配布後の受講コース変更は承っておりません。
但し、商品の欠陥や不良など当社原因による場合は受講コース変更を開講日から8日以内に限り、受け付けます。
欠陥・不良の条件は当社運営の教材が閲覧できなくなった場合に限ります。`
