<template>
  <h1>パスワードリセット</h1>
  <el-form
    ref="passwordResetFormRef"
    :model="passwordResetForm"
    :label-position="formLabelPosition"
    label-width="10rem"
    size="large"
  >
    <el-form-item
      label="メールアドレス"
      prop="email"
      :rules="[
        { type: 'email', message: 'メールアドレスの形式で入力してください' },
        { required: true, message: '入力が必須の項目です' },
      ]"
    >
      <el-input
        class="login-input-field"
        type="email"
        v-model="passwordResetForm.email"
        placeholder="メールアドレスを入力してください"
        :prefix-icon="Message"
      >
      </el-input>
    </el-form-item>
    <el-form-item label-width="auto">
      <el-button
        type="primary"
        @click="passwordResetSubmit(passwordResetFormRef)"
        :icon="Check"
        class="login-button"
        >リセットする</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script setup>
import { ElForm } from "element-plus"
import {
  showSuccessedMessage,
  showFailedMessage,
  showErrorMessage,
} from "@/lib/showMessages"
import { firebaseService } from "@/services/firebase"
import { ref, reactive } from "vue"
import { Message, Check } from "@element-plus/icons-vue"
import { formLabelPosition } from "@/lib/login"

const passwordResetForm = reactive({
  email: "",
})

const passwordResetFormRef = ref()

const passwordResetSubmit = async (formElement) => {
  if (!formElement) return
  formElement.validate(async (valid) => {
    if (valid) {
      await firebaseService
        .sendPasswordResetEmail(passwordResetForm.email)
        .then(() => {
          formElement.resetFields()
        })
        .then(() => {
          showSuccessedMessage("パスワード再設定メールをお送りしました🔥🚀")
        })
        .catch((error) => {
          showFailedMessage("パスワード再設定メールの送信に失敗しました。")
          showErrorMessage(error)
        })
    } else {
      return false
    }
  })
}
</script>

<style lang="scss" scoped>
.login-input-field {
  @include pc {
    min-width: 500px;
  }
  max-width: 100%;
}
.login-button {
  width: 100%;
}
</style>
