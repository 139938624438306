const markdowns = new Map()

export class Markdown {
  constructor(markdown) {
    const { id, title, type, time, asyncImport } = markdown
    if (markdowns.get(id)) {
      throw new Error("duplicated chapter id " + id)
    }
    markdowns.set(id, markdown)

    Object.assign(this, { id, title, type, time, asyncImport })
  }
}

export const getMarkdownById = (id) => {
  if (typeof id !== "string") {
    throw new TypeError("id must be a string")
  }
  const markdown = markdowns.get(id)
  if (!markdown) {
    throw new TypeError("Unknown id " + id)
  }
  return markdown
}
