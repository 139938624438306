import firebase from "firebase/compat/app"
import { makeUserProfile } from "@/lib/firebase"

export const firebaseService = {
  async signUp(email, password, profile) {
    try {
      const res = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
      const userProfile = makeUserProfile({
        ...profile,
        updatedBy: res.user.uid,
      })
      return await firebaseService.setUserProfile(res.user.uid, userProfile)
    } catch (error) {
      if (firebase.auth().currentUser) {
        firebase.auth().currentUser.delete()
      }
      throw error
    }
  },
  signInWithEmailAndPassword(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  },
  signOut() {
    return firebase.auth().signOut()
  },
  sendEmailVerification() {
    firebase.auth().languageCode = "ja"
    return firebase.auth().currentUser.sendEmailVerification()
  },
  sendPasswordResetEmail(email) {
    firebase.auth().languageCode = "ja"
    return firebase.auth().sendPasswordResetEmail(email)
  },
  getUsers(modifier = (query) => query.orderBy("createdAt", "desc")) {
    const query = modifier(firebase.firestore().collection("users"))
    return query.get()
  },
  setUserProfile(uid, userProfile) {
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .set({
        ...userProfile,
        updatedBy: firebase.auth().currentUser.uid || "",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  },
  updateUserProfile(uid, userProfile) {
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        ...userProfile,
        updatedBy: firebase.auth().currentUser.uid || "",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  },
  subscribeAuthState(callback) {
    return new Promise((resolve) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (!user) {
          callback({ user: null, userProfile: null })
        } else {
          const doc = await firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get()
          if (!doc.exists) {
            throw new Error("ユーザーのプロファイルが存在しません。")
          }
          callback({ user, userProfile: doc.data() })
        }
        resolve(unsubscribe)
      })
    })
  },
  getCourses() {
    return firebase
      .firestore()
      .collection("courses")
      .orderBy("term", "asc")
      .get()
  },
  subscribeUser(uid, callback) {
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .onSnapshot(callback)
  },
  updateUserAdminMemo(uid, adminMemo) {
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        adminMemo,
        updatedBy: firebase.auth().currentUser.uid || "",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  },
  updateUserDatabaseId(uid, databaseId) {
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        databaseId,
        updatedBy: firebase.auth().currentUser.uid || "",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  },
  searchMarkdownFromNotionDatabase(databaseId, title) {
    return firebase.functions().httpsCallable("notion")({
      database_id: databaseId,
      title,
    })
  },
}
