<template>
  <AdminBaseLayout>
    <template #navigation><AdminNavBar /></template>
    <template #main><AdminManageUser /></template>
  </AdminBaseLayout>
</template>

<script>
import AdminBaseLayout from "@/components/admin/AdminBaseLayout.vue"
import AdminManageUser from "@/components/admin/AdminManageUser.vue"
import AdminNavBar from "@/components/admin/AdminNavBar.vue"
export default {
  components: { AdminBaseLayout, AdminManageUser, AdminNavBar },
  setup() {},
}
</script>
