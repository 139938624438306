<script>
import { defineComponent, h } from "vue"
import { article, pushHeading } from "@/lib/article"

/**
 * ArticleHeading
 * markdown-it/plugins/heading.js のなかから使う
 */
export default defineComponent({
  name: "ArticleHeading",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    if (!article.headingsFixed) {
      const heading = { id: props.id, title: props.title, level: props.level }
      pushHeading(article, heading)
    }
    return { article }
  },
  render() {
    const tag = `h${this.level}`
    return h(
      tag,
      {
        id: this.id,
      },
      this.$slots.default()
    )
  },
})
</script>

<style lang="scss" scoped></style>
