<template>
  <BaseLayout>
    <div class="ArticleShow__container">
      <div class="ArticleShow__header">
        <h1 class="ArticleShow__title">{{ article.attributes.title }}</h1>
        <div v-if="article.attributes.time">
          <div class="ArticleShow__timer--text">取組目安時間</div>
          <div class="ArticleShow__timer--clock" :style="timerStyle">
            {{ article.attributes.time }}分
          </div>
        </div>
      </div>

      <div id="article">
        <component :is="article.component"></component>
        <ArticleUnderstandingButtons
          :article="article"
          v-if="article.id && article.id !== '404'"
        />
      </div>
    </div>
    <template #aside>
      <div class="ArticleShow__links">
        <ArticleTOC style="margin: 0" />
      </div>
    </template>
  </BaseLayout>
</template>

<script>
import { defineComponent } from "vue"

import { article, watchArticleRoute } from "@/lib/article"

import BaseLayout from "@/components/BaseLayout"
import ArticleTOC from "@/components/article/ArticleTOC.vue"
import ArticleUnderstandingButtons from "@/components/article/ArticleUnderstandingButtons.vue"

export default defineComponent({
  components: {
    BaseLayout,
    ArticleTOC,
    ArticleUnderstandingButtons,
  },
  setup() {
    watchArticleRoute()
    return {
      article,
    }
  },
  computed: {
    timerStyle() {
      const time = this.article.attributes.time / 0.6

      return {
        background: `radial-gradient(#fff 55%, transparent 55%),
    conic-gradient(var(--primary) ${time}%, #f2f2f2 ${time}% 100%)`,
      }
    },
  },
})
</script>

<style lang="scss">
#article {
  .hljs {
    position: relative;
    border-radius: 2px;
  }
  strong {
    font-weight: bold;
    color: red;
  }
  .hljs[data-filename] {
    padding-top: 2.5em;
  }
  .hljs[data-filename]::before {
    content: attr(data-filename);
    position: absolute;
    top: 0;
    left: 20px;
    padding: 3px 4px;
    color: var(--white);
    font-size: 0.8em;
    background-color: var(--gray);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  a {
    text-decoration: none;
  }
  th {
    box-sizing: border-box;
    border: 1px solid var(--text-dark);
    padding: 5px;
    background: var(--background);
    text-align: center;
  }
  td {
    box-sizing: border-box;
    border: 1px solid var(--text-dark);
    padding: 5px;
    text-align: center;
  }
  p {
    margin: 1.2em 0;
    line-height: 2em;
  }
  img {
    max-width: 100%;
    transform: scale(0.9);
  }
  code {
    font-family: "Roboto Mono", monospace;
  }
  blockquote {
    border-left: 4px solid var(--border);
    padding-left: 20px;
    padding: 10px 0 10px 20px;
    margin: 20px 0 20px 20px;
  }
  code.hljs {
    margin: 30px 0;
    padding: 2em;
    font-size: 0.9em;
  }

  details {
    border: 1px solid var(--border);
    border-radius: 2px;
    padding: 0.5em 0.5em 0;
  }

  summary {
    font-weight: bold;
    margin: -0.5em -0.5em 0;
    padding: 0.5em;
    cursor: pointer;
    &:hover,
    &:focus-within {
      background: var(--background);
    }
  }

  details[open] {
    padding: 0.5em;
  }

  details[open] summary {
    border-bottom: 1px solid var(--border);
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(transparent 60%, rgba(yellow, 0.8) 60%);
    padding-left: 0.5rem;
    display: block;
    margin-top: 3.5rem;
    margin-block-end: 1.5em;
    @include sp {
      font-size: 1.4rem;
    }
  }
  h2 {
    font-size: 1.7rem;
    font-weight: bold;
    background: linear-gradient(transparent 75%, rgba(yellow, 0.3) 75%);
    padding-left: 0.5rem;
    display: block;
    margin-top: 3rem;
    margin-block-end: 1.5em;
    @include sp {
      font-size: 1.2rem;
    }
  }
  h3 {
    border-bottom: 3px dotted var(--border);
    font-size: 1.4rem;
    font-weight: bold;
    padding-left: 0.5rem;
    padding-bottom: 0.25rem;
    margin-top: 2.4rem;
    margin-block-end: 1.5em;
    @include sp {
      font-size: 1rem;
    }
    &:before {
      display: inline-block;
      content: "";
      width: 1em;
      height: 1em;
      border-radius: 25%;
      border: 3px solid var(--primary);
      margin-right: 0.5rem;
      transform: translateY(2px);
    }
  }
  h4 {
    border-bottom: 3px dotted var(--border);
    font-size: 1.2rem;
    font-weight: bold;
    padding-left: 0.5rem;
    padding-bottom: 0.25rem;
    margin-top: 2.2rem;
    margin-block-end: 1.5em;
    @include sp {
      font-size: 1rem;
    }
    &:before {
      display: inline-block;
      content: "";
      width: 0.625em;
      height: 0.625em;
      border-radius: 25%;
      border: 2px solid var(--primary);
      margin-right: 0.5rem;
    }
  }
}

.ArticleShow__container {
  padding: 1rem 3.5rem;
  background: var(--white);
  @include sp() {
    padding: 1rem;
  }
}
.ArticleShow__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.ArticleShow__title {
  font-size: 2.6rem;
  font-weight: bold;
  display: block;
  margin: 1em 0;
  @include tablet() {
    font-size: 1.4rem;
    margin: 0.5em 0;
  }
}
.ArticleShow__timer--text {
  font-size: 1rem;
  color: var(--primary);
  margin-bottom: 0.5em;
}
.ArticleShow__timer--clock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  border-radius: 50%;
  font-weight: 600;
}
.ArticleShow__links {
  height: calc(100vh - 200px);
  position: sticky;
  top: 180px;
  font-size: 0.8rem;
  @include sp {
    display: none;
  }
}
</style>

<style lang="scss">
/* 教材マークダウン用CSS */
@mixin box($title, $color, $titleColor: var(--text-light)) {
  box-sizing: border-box;
  position: relative;
  margin: 4em 0 2.5em 0;
  padding: 1em;
  border: 2px solid rgba($color, 0.8);
  border-radius: 2px;
  background-color: rgba($color, 0.1);
  &:before {
    content: $title;
    position: absolute;
    top: 0;
    left: 1em;
    transform: translateY(-100%);
    padding: 0.25em 1em;
    border: 2px solid rgba($color, 0.8);
    border-radius: 2px;
    background-color: rgba($color, 0.8);
    font-weight: bold;
    color: $titleColor;
  }
}

#article {
  .box--try {
    @include box("TRY", #54c500);
  }
  .box--mac {
    @include box("Mac の場合", #ddd, #333);
  }
  .box--windows {
    @include box("Windows の場合", #ddd, #333);
  }
  .box--tips {
    @include box("補足", #ddd, #333);
  }
  .box--point {
    @include box("ポイント", #f4a500);
  }
  .box--goal {
    @include box("ゴール", #77a5f2);
  }
  .box--error {
    @include box("エラー", #f16666);
  }

  /* markdown-loader 内で付与したクラス */
  .codespan {
    background: rgba(#ccc, 0.4);
    border-radius: 2px;
    padding: 1px 5px;
    font-weight: bold;
  }

  /* カリキュラムページ用 */
  .category-page-links {
    margin: 1em;
  }
}
</style>
