<template>
  <div class="message--center message--big">{{ user.fullName }}さん</div>
  <br />
  <h1 class="message--center">
    メール認証が完了しました。<br />メンターによる認証をお待ち下さい。
  </h1>
  <el-button class="signout-button" size="large" type="primary" @click="signOut"
    >ログアウト</el-button
  >
</template>

<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import { signOut } from "@/lib/login"
const store = useStore()

const user = computed(() => store.getters.user)
</script>

<style lang="scss" scoped>
.message--big {
  font-size: 1.2rem;
  font-weight: bold;
}

.message--center {
  text-align: center;
}

.message--small {
  font-size: 0.8rem;
}

.signout-button {
  margin-top: 2rem;
}
</style>
