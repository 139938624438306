import { isBetween90days } from "./date-util"
import { ref, toRaw, reactive, computed, onUnmounted } from "vue"
import { firebaseService } from "@/services/firebase"
import { chapters, CHAPTER_ID } from "@/lib/chapter"
import { ElMessage } from "element-plus"
import { ADMIN_MEMO_TEMPLATE } from "@/lib/const"
export { makeCourseOptions, mountCourseOptions } from "@/lib/login"
export { formatDate } from "./date-util"
import store from "@/store"

export const users = ref([])
export const courseOptions = ref([])
export const isUsersLoading = ref(false)

const getCurrentCourseId = async () => {
  const coursesDocs = await firebaseService.getCourses()
  const courses = coursesDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  const currentCourse = courses.find((course) => {
    return isBetween90days(course.startAt)
  })
  return currentCourse ? currentCourse.id : null
}

export const getUsersForAdmin = async (
  modifier = (query) => query.orderBy("createdAt", "desc")
) => {
  isUsersLoading.value = true
  return await firebaseService.getUsers(modifier).then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const additionalData = {
        uid: doc.id,
        understandings: {},
      }
      const user = {
        ...additionalData,
        ...doc.data(),
      }
      users.value = [...users.value, user]
    })
    isUsersLoading.value = false
    return querySnapshot.docs[querySnapshot.docs.length - 1]
  })
}

export const getAllUsersForAdmin = async () => {
  users.value = []
  await getUsersForAdmin()
}

export const getCurrentCourseUsersForAdmin = async () => {
  const currentCourseId = await getCurrentCourseId()
  if (!currentCourseId) return
  users.value = []
  await getUsersForAdmin((query) =>
    query
      .where("registeredCourseId", "==", currentCourseId)
      .orderBy("createdAt", "desc")
  )
}

export const getOnlyNotMentorUsersForAdmin = async () => {
  users.value = []
  await getUsersForAdmin((query) =>
    query.where("isMentor", "==", false).orderBy("createdAt", "desc")
  )
}

export const getOnlyNotPermittedUsersForAdmin = async () => {
  users.value = []
  await getUsersForAdmin((query) => query.where("isPermitted", "==", false))
}

export const getUsersForFirstFetch = async () => {
  users.value = []
  queryCursol.value = undefined
  await get50UsersForAdmin()
}

const queryCursol = ref(undefined)

export const get50UsersForAdmin = async () => {
  if (!queryCursol.value) {
    const next = await getUsersForAdmin((query) =>
      query.limit(50).orderBy("createdAt", "desc")
    )
    queryCursol.value = next
    return
  }
  const next = await getUsersForAdmin((query) =>
    query.limit(50).orderBy("createdAt", "desc").startAfter(queryCursol.value)
  )
  queryCursol.value = next
}

export const updateUserProfile = (uid) => {
  isUsersLoading.value = true
  const userProfile = { ...toRaw(users.value.find((user) => user.uid === uid)) }
  delete userProfile.uid
  return firebaseService.updateUserProfile(uid, userProfile)
}

export const getAllArticleId = () => {
  return Object.values(CHAPTER_ID)
    .map((chapterId) => {
      const chapter = chapters.value.get(chapterId)
      const articleIdInChapters =
        chapter?.markdowns.map((markdown) => markdown.id) ?? []
      return articleIdInChapters
    })
    .reduce((prev, cur) => [...prev, ...cur], [])
}

export const getCourseLabel = (id) =>
  computed(() => {
    if (!courseOptions.value) return ""
    const course = courseOptions.value.find((course) => {
      return course.value === id
    })
    if (!course) return "Not Found (undefined)"
    return course.label
  })

// Admin画面のユーザー情報編集用のキャッシュ

const isEditCacheMap = reactive(new Map())

export const isEdit = computed(() => (uid) => !!isEditCacheMap.get(uid))

export const edit = (uid) => {
  const userData = users.value.find((user) => user.uid === uid)
  isEditCacheMap.set(uid, { ...toRaw(userData) })
}

export const editCanceled = (uid) => {
  ElMessage({
    message: `${uid}の変更をキャンセルしました。`,
    type: "warning",
    offset: 72,
  })
  const userDataIndex = users.value.findIndex((user) => user.uid === uid)
  users.value[userDataIndex] = isEditCacheMap.get(uid)
  isEditCacheMap.delete(uid)
}

export const editUploaded = (uid) => {
  updateUserProfile(uid)
    .then(() => {
      ElMessage({
        message: `${uid}の変更をアップロードしました。`,
        type: "success",
        offset: 72,
      })
      isEditCacheMap.set(uid, undefined)
      isUsersLoading.value = false
    })
    .catch((error) => {
      console.error(error)
      editCanceled(uid)
      isUsersLoading.value = false
    })
}

// expandで開いたタブのsubscriptionの管理

export const userSubscriptions = reactive(new Map())

const unsubscribeUser = (uid) => {
  userSubscriptions.get(uid).unsubscribe()
  userSubscriptions.delete(uid)
}

const subscribeUser = (uid) => {
  const adminMemo = ADMIN_MEMO_TEMPLATE
  const subscribeUserAdminMemo = () => ({
    adminMemo: adminMemo,
    isLastUpdatedBySelf: false,
    unsubscribe: firebaseService.subscribeUser(
      uid,
      updateAdminMemoBySubscription(uid)
    ),
  })

  userSubscriptions.set(uid, subscribeUserAdminMemo())
}

export const updateAdminMemo = (uid, newUserAdminMemo, updatedBySelf) => {
  const user = userSubscriptions.get(uid)
  if (!user) return
  userSubscriptions.set(uid, {
    ...user,
    isLastUpdatedBySelf: updatedBySelf,
    adminMemo: newUserAdminMemo,
  })
}

export const onExpandRow = (row) => {
  if (userSubscriptions.has(row.uid)) {
    unsubscribeUser(row.uid)
    return
  }
  subscribeUser(row.uid)
}

export const updateAdminMemoBySubscription = (uid) => (doc) => {
  const data = doc.data()
  if (!data.adminMemo) return
  if (!getUserAdminMemo(uid)) return
  if (getLastUpdatedBySelf(uid) && data.updatedBy === store.getters.user.uid)
    return
  updateAdminMemo(uid, data.adminMemo, false)
}

export const getUserAdminMemo = (uid) => {
  return userSubscriptions.get(uid).adminMemo
}

export const getLastUpdatedBySelf = (uid) => {
  return userSubscriptions.get(uid).isLastUpdatedBySelf
}

export const registerUnsubscribeAllUser = () =>
  onUnmounted(() => {
    userSubscriptions.forEach((subscription) => {
      subscription.unsubscribe()
    })
  })

// notion database
export const getNotionData = async (
  databaseId,
  name,
  responseRef,
  isLoadingRef
) => {
  isLoadingRef.value = true
  if (!databaseId) {
    isLoadingRef.value = false
    return
  }
  const notionData = await firebaseService.searchMarkdownFromNotionDatabase(
    databaseId,
    name
  )
  responseRef.value = notionData
  isLoadingRef.value = false
}
