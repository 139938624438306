<template>
  <BaseLayout>
    <div class="HomeView__container">
      <GoalInput style="margin-top: 2rem" />
      <ScheduleTable style="margin-top: 4rem" />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/components/BaseLayout.vue"
import GoalInput from "@/components/goal/GoalInput.vue"
import ScheduleTable from "@/components/schedule/ScheduleTable.vue"
export default {
  components: {
    BaseLayout,
    GoalInput,
    ScheduleTable,
  },
}
</script>

<style lang="scss" scoped>
.HomeView__container {
  padding: 3rem;
  background: var(--white);
  @include sp() {
    padding: 0.5rem;
  }
}
</style>
