<template>
  <el-container>
    <el-main class="login-layout">
      <img :src="logoImagePath" alt="webexpert course logo" class="logo" />
      <router-view />
      <LoginLinks v-if="!$store.getters.isSignedUp" />
    </el-main>
  </el-container>
</template>

<script setup>
import LoginLinks from "@/components/login/LoginLinks.vue"
import { computed } from "vue"
import { useRoute } from "vue-router"
const route = useRoute()
const logoImagePath = computed(() =>
  route.name === "LoginSignin"
    ? require("@/assets/images/home_background.gif")
    : require("@/assets/images/home_background.png")
)
const backgroundColor = computed(() =>
  route.name === "LoginSignin" ? "#fbfbfb" : "#ffffff"
)
</script>

<style lang="scss" scoped>
.login-layout {
  min-height: 100vh;
  background-color: v-bind(backgroundColor);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.logo {
  width: 100%;
  max-height: 512px;
  object-fit: contain;
}
</style>
