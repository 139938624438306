<template>
  <div class="GoalInput__container">
    <h1>今週の目標</h1>
    <textarea
      v-model="ls.goal"
      placeholder="目標を入力してください"
      class="GoalInput__textarea"
    ></textarea>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
export default defineComponent({
  setup() {
    if (!localStorage.goal) {
      localStorage.goal = ""
    }
    const ls = ref(localStorage)

    return { ls }
  },
})
</script>

<style lang="scss" scoped>
// .GoalInput__container {
// }
.GoalInput__textarea {
  width: 100%;
  min-height: 3rem;
  resize: vertical;
  font-size: 1.5rem;
  color: var(--text-dark);
}
</style>
