<template>
  <section class="ArticleChapter__container">
    <div class="ArticleChapter__heading-container">
      <h1 class="ArticleChapter__heading">{{ chapter.title }}</h1>
    </div>
    <ol class="ArticleChapter__articles">
      <router-link
        v-for="markdown in chapter.markdowns"
        :key="markdown.id"
        :to="{ name: 'Article', params: { articleId: markdown.id } }"
        custom
        v-slot="{ navigate }"
      >
        <div
          class="ArticleChapter__li-container"
          @click="navigate"
          @keypress.enter="navigate"
          role="link"
          tabindex="1"
        >
          <li class="ArticleChapter__li" :data-type="markdown.type">
            <span class="ArticleChapter__link">
              {{ markdown.title }}
            </span>
            <span class="ArticleChapter__link">
              <span
                v-show="getUnderstanding(markdown.id) === 'good'"
                class="ArticleChapter__link--icon"
                >✅</span
              >
              <span
                v-show="getUnderstanding(markdown.id) === 'poor'"
                class="ArticleChapter__link--icon"
                >☑️</span
              >
            </span>
          </li>
          <span v-if="markdown.time" class="ArticleChapter__link--timer"
            >{{ markdown.time }} 分</span
          >
        </div>
      </router-link>
    </ol>
  </section>
</template>

<script>
import { chapters } from "@/lib/chapter"
import { getUnderstanding } from "@/lib/understandings"

export default {
  props: {
    chapterId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chapter = chapters.value.get(props.chapterId)
    return { chapter, getUnderstanding }
  },
}
</script>

<style lang="scss" scoped>
// .ArticleChapter__container {
// }
.ArticleChapter__heading-container {
  display: flex;
  align-items: center;
  min-height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background: var(--primary);
  font-size: 0.75rem;
}
.ArticleChapter__heading {
  font-size: 1.75rem;
  font-weight: bold;
  letter-spacing: 0.125rem;
  color: var(--text-light);
}
.ArticleChapter__articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-auto-rows: 4rem;
  margin: 0;
  padding: 0;
  @include pc {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.ArticleChapter__li-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--border);
  background: var(--white);
  transition: background ease-out 0.15s;
  cursor: pointer;
  &:hover,
  &:focus-within {
    background: var(--background);
  }
}
.ArticleChapter__li {
  line-height: 1.5;
  list-style-position: inside;
}
.ArticleChapter__link {
  letter-spacing: 0.125rem;
  &:before {
    content: "";
    display: inline-block;
    width: 0.5rem;
    height: 1rem;
  }
}
.ArticleChapter__link--timer {
  display: inline-block;
  min-width: 4rem;
  line-height: 2;
  text-align: center;
  border-radius: 0.5rem;
  border: 1px solid var(--primary);
  font-weight: 600;
  color: var(--primary);
}
</style>
