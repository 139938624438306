<template>
  <div class="message--center message--big">{{ user.fullName }}さん</div>
  <br />
  <div class="message--center">
    登録いただいたメールアドレス<span class="message--small message--bg-gray">{{
      user.email
    }}</span
    >に認証メールをお送りしました。<br />
    メールに記載のリンクをクリックして、認証を行ってください。<br />
    <span class="message--small"
      >(メールが見付からない場合は、「迷惑メールフォルダ」を確認してください。)</span
    >
  </div>
  <div>
    <el-button
      class="signout-button"
      size="large"
      type="danger"
      :loading="restrictClickResendEmail"
      :disabled="restrictClickResendEmail"
      @click="sendEmailVerification"
      >認証メールを再送する</el-button
    >
  </div>
  <div>
    <el-button
      class="signout-button"
      size="large"
      type="primary"
      @click="signOut"
      >ログアウト</el-button
    >
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { computed, onMounted, ref } from "vue"
import { signOut, sendEmailVerification } from "@/lib/login"

const store = useStore()
const user = computed(() => store.getters.user)
const restrictClickResendEmail = ref(true)
const setRestrictClickResendEmail = () => {
  restrictClickResendEmail.value = true
  setTimeout(() => {
    restrictClickResendEmail.value = false
  }, 5000)
}
onMounted(() => {
  setRestrictClickResendEmail()
})
</script>

<style lang="scss" scoped>
.message--big {
  font-size: 1.2rem;
  font-weight: bold;
}

.message--center {
  text-align: center;
}

.message--small {
  font-size: 0.8rem;
}

.message--bg-gray {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: var(--text-light);
  background-color: var(--gray);
}

.signout-button {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
