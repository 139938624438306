import { ElMessage } from "element-plus"

export const showSuccessedMessage = (message) => {
  ElMessage({
    message: message,
    type: "success",
    offset: 72,
    center: true,
  })
}

export const showFailedMessage = (message) => {
  ElMessage({
    message: message,
    type: "error",
    offset: 72,
    center: true,
  })
}

export const showErrorMessage = (error) => {
  ElMessage({
    message: error.message,
    type: "info",
    offset: 144,
    center: true,
  })
}
