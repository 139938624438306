<template>
  <el-menu
    :default-active="activeIndex"
    mode="horizontal"
    class="AdminNavBar__menu"
    @select="handleSelect"
  >
    <el-menu-item index="current-course"> Current Course </el-menu-item>
    <el-menu-item index="waiting-list"> Waiting List </el-menu-item>
    <el-menu-item index="by-created-at"> By CreatedAt </el-menu-item>
    <el-menu-item index="not-mentor-only"> Not Mentor Only </el-menu-item>
  </el-menu>
</template>

<script setup>
import { ref, onMounted } from "vue"
import {
  getOnlyNotMentorUsersForAdmin,
  getUsersForFirstFetch,
  getCurrentCourseUsersForAdmin,
  getOnlyNotPermittedUsersForAdmin,
} from "@/lib/admin"
const activeIndex = ref("current-course")
const handleSelect = (key) => {
  switch (key) {
    case "by-created-at": {
      getUsersForFirstFetch()
      break
    }
    case "waiting-list": {
      getOnlyNotPermittedUsersForAdmin()
      break
    }
    case "current-course": {
      getCurrentCourseUsersForAdmin()
      break
    }
    case "not-mentor-only": {
      getOnlyNotMentorUsersForAdmin()
      break
    }
    default: {
      getCurrentCourseUsersForAdmin()
      break
    }
  }
}

onMounted(() => {
  handleSelect()
})
</script>
