<template>
  <h1>ログイン</h1>
  <el-form
    ref="signInFormRef"
    :model="signInform"
    :label-position="formLabelPosition"
    label-width="10rem"
    size="large"
  >
    <el-form-item
      label="メールアドレス"
      prop="email"
      :rules="[
        { type: 'email', message: 'メールアドレスの形式で入力してください' },
        { required: true, message: '入力が必須の項目です' },
      ]"
    >
      <el-input
        class="login-input-field"
        type="email"
        v-model="signInform.email"
        placeholder="メールアドレスを入力してください"
        :prefix-icon="Message"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      label="パスワード"
      prop="password"
      :rules="[
        { type: 'string', required: true, message: '入力が必須の項目です' },
      ]"
    >
      <el-input
        class="login-input-field"
        type="password"
        v-model="signInform.password"
        placeholder="パスワードを入力してください"
        :prefix-icon="Lock"
      />
    </el-form-item>
    <el-form-item label-width="auto">
      <el-button
        type="primary"
        @click="signInSubmit(signInFormRef)"
        :icon="Check"
        class="login-button"
        >ログイン</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script setup>
/// @ts-check
import { Lock, Message, Check } from "@element-plus/icons-vue"
import { formLabelPosition, watchIsSignIn } from "@/lib/login"
import { reactive, ref } from "vue"
import { firebaseService } from "@/services/firebase"
import { showErrorMessage, showFailedMessage } from "@/lib/showMessages"
import { ElForm } from "element-plus"

watchIsSignIn()

const signInform = reactive({
  email: "",
  password: "",
})

// <el-form ref="signInFormRef" /> で登録される
const signInFormRef = ref()

/**
 * @param {InstanceType<typeof ElForm> | undefined} formElement
 * */
const signInSubmit = async (formElement) => {
  if (!formElement) return
  formElement.validate(async (valid) => {
    if (valid) {
      await firebaseService
        .signInWithEmailAndPassword(signInform.email, signInform.password)
        .then(() => {
          formElement.resetFields()
        })
        .catch((error) => {
          showFailedMessage("ログインに失敗しました。")
          showErrorMessage(error)
        })
    } else {
      return false
    }
  })
}
</script>

<style lang="scss" scoped>
.login-input-field {
  @include pc {
    min-width: 500px;
  }
  max-width: 100%;
}
.login-button {
  width: 100%;
}
</style>
