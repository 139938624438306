import { createApp } from "vue"
import ElementPlus from "element-plus"

import "@/plugins/firebase"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "element-plus/dist/index.css"
import "highlight.js/styles/base16/gruvbox-dark-medium.css"
import "github-markdown-css/github-markdown-light.css"
import "@/assets/stylesheets/global.scss"
import "@/assets/markdowns"

import ArticleLink from "@/components/article/ArticleLink.vue"
import ArticleHeading from "@/components/article/ArticleHeading.vue"

const app = createApp(App).use(store).use(router).use(ElementPlus)

app.component("ArticleLink", ArticleLink)
app.component("ArticleHeading", ArticleHeading)

app.mount("#app")
