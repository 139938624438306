<template>
  <ol class="ArticleTOC__container">
    <li
      v-for="heading in article.headings"
      :key="heading.id"
      @click="replaceTo(heading)"
      class="ArticleTOC__link"
      :data-level="heading.level"
    >
      {{ heading.title }}
    </li>
  </ol>
</template>

<script>
import { useRouter } from "vue-router"

import { article } from "@/lib/article"

export default {
  setup() {
    const router = useRouter()
    const replaceTo = (heading) => {
      router.replace("#" + heading.id)
    }
    return { article, replaceTo }
  },
}
</script>

<style lang="scss" scoped>
.ArticleTOC__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 18.5em;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
}
.ArticleTOC__link {
  list-style-type: none;
  text-decoration: none;
  padding: 4px 0;
  border-radius: 2px;
  color: black;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
  @for $size from 1 through 5 {
    &[data-level="#{$size}"] {
      padding-left: 1em * ($size - 0.5);
    }
  }
}
</style>
