<template>
  <h1>アカウント作成</h1>
  <el-form
    ref="signUpFormRef"
    :model="signUpForm"
    :label-position="formLabelPosition"
    label-width="11rem"
    size="large"
    @submit.prevent="signUpSubmit(signUpFormRef)"
  >
    <el-form-item
      label="名前"
      prop="fullName"
      :rules="[{ required: true, message: '入力が必須の項目です' }]"
    >
      <el-input
        class="signup-input-field"
        v-model="signUpForm.fullName"
        placeholder="フルネーム（漢字）"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      label="メールアドレス"
      prop="email"
      :rules="[
        {
          type: 'email',
          message: 'メールアドレスの形式で入力してください',
        },
        { required: true, message: '入力が必須の項目です' },
      ]"
    >
      <el-input
        class="signup-input-field"
        type="email"
        v-model="signUpForm.email"
        placeholder="メールアドレスを入力してください"
        :prefix-icon="Message"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      label="パスワード"
      prop="password"
      :rules="[
        { type: 'string', required: true, message: '入力が必須の項目です' },
      ]"
    >
      <el-input
        class="signup-input-field"
        type="password"
        v-model="signUpForm.password"
        placeholder="パスワードを入力してください"
        :prefix-icon="Lock"
      />
    </el-form-item>
    <el-form-item
      label="パスワード(確認)"
      prop="confirmedPassword"
      :rules="[
        { type: 'string', required: true, message: '入力が必須の項目です' },
        { validator: isSamePasswordValidation, trigger: 'blur' },
      ]"
    >
      <el-input
        class="signup-input-field"
        type="password"
        v-model="signUpForm.confirmedPassword"
        placeholder="確認のためもう一度パスワードを入力してください"
        :prefix-icon="Lock"
      />
    </el-form-item>

    <el-form-item
      label="受講期"
      prop="registeredCourseId"
      :rules="[
        { type: 'string', required: true, message: '選択が必須の項目です' },
      ]"
    >
      <el-select
        v-model="signUpForm.registeredCourseId"
        class="signup-input-field"
        placeholder="受講する期を選択してください"
        :rules="[
          { type: 'string', required: true, message: '選択が必須の項目です' },
        ]"
      >
        <el-option
          v-for="course in signUpCourseOptions"
          :key="course.value"
          :label="course.label"
          :value="course.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item
      label="登録タイプ(自動選択)"
      prop="registerType"
      :rules="[
        { type: 'string', required: true, message: '選択が必須の項目です' },
      ]"
    >
      <el-radio-group
        class="signup-input-field"
        v-model="signUpForm.registerType"
        disabled
      >
        <el-radio label="受講生" size="large" border />
        <el-radio label="メンター" size="large" border />
      </el-radio-group>
    </el-form-item>

    <el-form-item
      label-width="auto"
      label="教材配布に伴う同意事項"
      prop="isAgreedWithContentsTerm"
      class="agreements"
      :rules="[
        {
          type: 'boolean',
          required: true,
          message: '教材配布に関する同意事項に同意してください',
        },
        { validator: isCheckValidation, trigger: 'blur' },
      ]"
    >
      <el-card class="agreements__card">
        <div class="agreements__content">{{ AGREEMENTS_SENTENCE }}</div>
      </el-card>
      <el-checkbox
        label="上記すべてに対して同意します"
        v-model="signUpForm.isAgreedWithContentsTerm"
      />
    </el-form-item>

    <el-form-item label-width="auto">
      <el-button
        type="primary"
        native-type="submit"
        :icon="Check"
        class="signup-button"
        >アカウント作成</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script setup>
import { reactive, ref } from "vue"
import { Lock, Message, Check } from "@element-plus/icons-vue"
import {
  formLabelPosition,
  sendEmailVerification,
  signUpCourseOptions,
  mountCourseOptions,
  validateCourseOptionsValue,
} from "@/lib/login"
import { firebaseService } from "@/services/firebase"
import { showErrorMessage, showFailedMessage } from "@/lib/showMessages"
import { ElForm } from "element-plus"
import { useRouter, useRoute } from "vue-router"
import { AGREEMENTS_SENTENCE } from "@/lib/const"

mountCourseOptions(signUpCourseOptions, { useInForm: true, useFilter: true })

const router = useRouter()
const route = useRoute()

const matchRegisterType = () => {
  const { registerType } = route.query
  if (!registerType) return "受講生"
  switch (registerType) {
    case "student": {
      return "受講生"
    }
    case "mentor": {
      return "メンター"
    }
    default: {
      return "受講生"
    }
  }
}

const signUpForm = reactive({
  fullName: "",
  email: "",
  password: "",
  confirmedPassword: "",
  registeredCourseId: "",
  registerType: matchRegisterType(),
  isAgreedWithContentsTerm: false,
})

const isSamePasswordValidation = (_rule, _value, callback) => {
  if (signUpForm.password !== signUpForm.confirmedPassword) {
    callback(new Error("入力されたパスワードが一致しません。"))
  } else {
    callback()
  }
}

const isCheckValidation = (_rule, value, callback) => {
  value
    ? callback()
    : callback(new Error("教材配布に関する同意事項に同意してください"))
}

// <el-form ref="signUpFormRef" /> で登録される
const signUpFormRef = ref()

/**
 * @param {InstanceType<typeof ElForm> | undefined} formElement
 * */
const signUpSubmit = (formElement) => {
  if (!formElement) return
  formElement.validate(async (valid) => {
    if (!valid) {
      showFailedMessage("表示に沿って正しい値を入力してください。")
      return
    }
    try {
      await firebaseService.signUp(signUpForm.email, signUpForm.password, {
        fullName: signUpForm.fullName,
        registeredCourseId: validateCourseOptionsValue(
          signUpForm.registeredCourseId
        ),
        isMentor: signUpForm.registerType === "メンター",
      })
      formElement.resetFields()
      await sendEmailVerification()
      await router.push({ path: "/login/confirmed" })
    } catch (error) {
      showFailedMessage("アカウント作成に失敗しました。")
      showErrorMessage(error)
    }
  })
}
</script>

<style lang="scss" scoped>
.signup-input-field {
  @include pc {
    min-width: 500px;
  }
  max-width: 100%;
}
.signup-button {
  width: 100%;
}

.agreements {
  width: 100%;
  @include pc {
    width: 800px;
  }
}

.agreements__card {
  height: 25rem;
  overflow-y: scroll;
  width: 100%;
}

.agreements__content {
  white-space: pre-line;
}
</style>
