/**
 * @typedef {string} MarkdownId
 * @typedef {"common"|"text"|"recipe"} MarkdownType
 * @typedef {{ id: MarkdownId, title: string, type: MarkdownType, asyncImport: Promise<string> }} Markdown
 */

import { Chapter, CHAPTER_ID } from "@/lib/chapter"
import { Markdown } from "@/lib/markdown"

/**
 * Chapter は new Chapter で登録した順番に一覧に表示される
 */

new Markdown({
  id: "404",
  title: "ページが見つかりません",
  type: "common",
  asyncImport: () => import(/* webpackChunkName: "common" */ "./404.md"),
})

new Chapter({
  id: CHAPTER_ID.pre,
  title: "Web Expert コースについて",
  markdowns: [
    {
      id: "pre_readme",
      title: "はじめに読んでください",
      type: "text",
      time: 5,
      asyncImport: () =>
        import(
          /* webpackChunkName: "pre" */ "./webex/pre/readme/はじめに読んでください.md"
        ),
    },
    {
      id: "pre_todo",
      title: "WebExpertコースが開講するまでに",
      type: "text",
      time: 5,
      asyncImport: () =>
        import(
          /* webpackChunkName: "pre" */ "./webex/pre/readme/WebExpertコースが開講するまでに.md"
        ),
    },
    {
      id: "pre_about_course",
      title: "WebExpertコース要綱",
      type: "text",
      time: 5,
      asyncImport: () =>
        import(
          /* webpackChunkName: "pre" */ "./webex/pre/readme/WebExpertコース要綱.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.setup,
  title: "学習の手引き",
  markdowns: [
    {
      id: "kickoff_how_to_read_artcile",
      title: "教材の読み方",
      type: "text",
      time: 5,
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/教材の読み方.md"
        ),
    },
    {
      id: "kickoff_preinstall",
      title: "必要なソフトウェアをインストールする",
      type: "text",
      time: 10,
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/必要なソフトウェアをインストールする.md"
        ),
    },
    {
      id: "kickoff_start",
      title: "学習の準備をする",
      type: "text",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/学習の準備をする.md"
        ),
    },
    {
      id: "kickoff_start_windows",
      title: "学習の準備をする - Windowsのみの設定",
      type: "text",
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/学習の準備をする-Windowsのみの設定.md"
        ),
    },
    {
      id: "kickoff_start_git",
      title: "学習の準備をする - Gitの設定",
      type: "text",
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/学習の準備をする-Gitの設定.md"
        ),
    },
    {
      id: "kickoff_start_nodejs",
      title: "学習の準備をする - Nodejsの設定",
      type: "text",
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/学習の準備をする-Nodejsの設定.md"
        ),
    },
    {
      id: "kickoff_start_vscode",
      title: "学習の準備をする - VS Codeの設定",
      type: "text",
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/学習の準備をする-VS Codeの設定.md"
        ),
    },
    {
      id: "kickoff_start_sample_code",
      title: "学習の準備をする - サンプルコード",
      type: "text",
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/学習の準備をする-サンプルコード.md"
        ),
    },
    {
      id: "kickoff_sample_code",
      title: "js-recipeで実際に学習する",
      type: "text",
      time: 25,
      asyncImport: () =>
        import(
          /* webpackChunkName: "kickoff" */ "./webex/kickoff/js-recipeで実際に学習する.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.pc,
  title: "パソコン",
  markdowns: [
    {
      id: "pc_terminal",
      title: "ターミナルの基礎",
      type: "text",
      time: 10,
      asyncImport: () =>
        import(/* webpackChunkName: "pc" */ "./webex/pc/ターミナルの基礎.md"),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.js_basic,
  title: "JavaScript の基礎",
  markdowns: [
    {
      id: "js_basic_firststep",
      title: "JavaScriptで遊んでみよう",
      type: "text",
      time: 30,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_basic/JavaScriptで遊んでみよう.md"
        ),
    },
    {
      id: "js_basic_variables",
      title: "変数・定数・代入",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_basic/JavaScriptの変数・定数・代入.md"
        ),
    },
    {
      id: "js_basic_operator",
      title: "式と演算子",
      type: "text",
      time: 30,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_basic/JavaScriptの式と演算子.md"
        ),
    },
    {
      id: "js_basic_function",
      title: "関数",
      type: "text",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_basic/JavaScriptの関数.md"
        ),
    },
    {
      id: "js_basic_object",
      title: "オブジェクト",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_basic/JavaScriptのオブジェクト.md"
        ),
    },
    {
      id: "js_basic_array",
      title: "配列",
      type: "text",
      time: 25,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_basic/JavaScriptの配列.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.js_dom,
  title: "DOM",
  markdowns: [
    {
      id: "js_dom",
      title: "JavaScriptとDOM",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_dom" */ "./webex/js_dom/JavaScriptとDOM.md"
        ),
    },
    {
      id: "js_dom_manipulation",
      title: "要素を取得・変更する",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_dom" */ "./webex/js_dom/JavaScriptで要素を取得・変更する.md"
        ),
    },
    {
      id: "js_dom_manipulation_create_and_append",
      title: "要素を作成・表示する",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_dom" */ "./webex/js_dom/JavaScriptで要素を作成・表示する.md"
        ),
    },
    {
      id: "js_dom_event",
      title: "イベント",
      type: "text",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_dom" */ "./webex/js_dom/JavaScriptのイベント.md"
        ),
    },
    {
      id: "js_basic_localStorage",
      title: "ブラウザへのデータ保存(localStorage)",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "js_basic" */ "./webex/js_dom/ブラウザへのデータ保存(localStorage).md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.recipe1,
  title: "レシピ - レベル１",
  markdowns: [
    {
      id: "recipe1_three",
      title: "3の倍数",
      type: "recipe",
      time: 20,
      asyncImport: () =>
        import(/* webpackChunkName: "recipe1" */ "./webex/recipe1/3の倍数.md"),
    },
    {
      id: "recipe1_three_practice_1",
      title: "【発展】3の倍数 - FizzBuzz",
      type: "recipe",
      time: 30,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/3の倍数-発展-FizzBuzz.md"
        ),
    },
    {
      id: "recipe1_three_practice_2",
      title: "【発展】3の倍数 - 3が大好き",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/3の倍数-発展-３が大好き.md"
        ),
    },
    {
      id: "recipe1_three_practice_3",
      title: "【発展】3の倍数 - 元気な秒針",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/3の倍数-発展-元気な秒針.md"
        ),
    },
    {
      id: "recipe1_counter",
      title: "カウンター",
      type: "recipe",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/カウンター.md"
        ),
    },
    {
      id: "recipe1_counter_practice_1",
      title: "【発展】カウンター - 便利なボタン",
      type: "recipe",
      time: 10,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/カウンター-発展-便利なボタン.md"
        ),
    },
    {
      id: "recipe1_counter_practice_2",
      title: "【発展】カウンター - 電卓",
      type: "recipe",
      time: 30,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/カウンター-発展-電卓.md"
        ),
    },
    {
      id: "recipe1_shape",
      title: "□と○",
      type: "recipe",
      time: 20,
      asyncImport: () =>
        import(/* webpackChunkName: "recipe1" */ "./webex/recipe1/□と○.md"),
    },
    {
      id: "recipe1_shape_practice_1",
      title: "【発展】□と○ - いろんなイベント",
      type: "recipe",
      time: 10,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/□と○-発展-いろんなイベント.md"
        ),
    },
    {
      id: "recipe1_shape_practice_2",
      title: "【発展】□と○ - △も！",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/□と○-発展-△も！.md"
        ),
    },
    {
      id: "recipe1_stopwatch",
      title: "ストップウォッチ",
      type: "recipe",
      time: 25,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/ストップウォッチ.md"
        ),
    },
    {
      id: "recipe1_stopwatch_practice_1",
      title: "【発展】ストップウォッチ - わずかなずれ",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/ストップウォッチ-発展-わずかなずれ.md"
        ),
    },
    {
      id: "recipe1_stopwatch_practice_2",
      title: "【発展】ストップウォッチ - 謎のずれ",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe1" */ "./webex/recipe1/ストップウォッチ-発展-謎のずれ.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.programming,
  title: "プログラミングの考え方",
  markdowns: [
    {
      id: "programming_brakedown",
      title: "プログラムを組み立てるために",
      type: "text",
      time: 25,
      asyncImport: () =>
        import(
          /* webpackChunkName: "programming" */ "./webex/programming/プログラムを組み立てるために.md"
        ),
    },
    {
      id: "programming_state",
      title: "Webアプリケーションと状態",
      type: "text",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "programming" */ "./webex/programming/Webアプリケーションと状態.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.recipe2,
  title: "レシピ - レベル２",
  markdowns: [
    {
      id: "recipe2_trello",
      title: "Trello（保存機能なし）",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/Trello（保存機能なし）.md"
        ),
    },
    {
      id: "recipe2_trello_practice_1",
      title: "【発展】Trello（保存機能なし） - エンターキー",
      type: "recipe",
      time: 30,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/Trello（保存機能なし）-発展-エンターキー.md"
        ),
    },
    {
      id: "recipe2_trello_practice_2",
      title: "【発展】Trello（保存機能なし） - 複数のリスト",
      type: "recipe",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/Trello（保存機能なし）-発展-複数のリスト.md"
        ),
    },
    {
      id: "recipe2_trello_practice_3",
      title: "【発展】Trello（保存機能なし） - 保存機能",
      type: "recipe",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/Trello（保存機能なし）-発展-保存機能.md"
        ),
    },
    {
      id: "recipe2_quiz",
      title: "クイズ（１問）",
      type: "recipe",
      time: 35,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/クイズ（１問）.md"
        ),
    },
    {
      id: "recipe2_quiz_practice_1",
      title: "【発展】クイズ（１問） - マイクイズ",
      type: "recipe",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/クイズ（１問）-発展-マイクイズ.md"
        ),
    },
    {
      id: "recipe2_quiz_practice_2",
      title: "【発展】クイズ（１問） - 四択問題",
      type: "recipe",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/クイズ（１問）-発展-四択問題.md"
        ),
    },
    {
      id: "recipe2_quiz_practice_3",
      title: "【発展】クイズ（１問） - 選択肢の数とボタンの表示",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/クイズ（１問）-発展-選択肢の数とボタンの表示.md"
        ),
    },
    {
      id: "recipe2_quiz_practice_4",
      title: "【発展】クイズ（１問） - クイズ（複数）",
      type: "recipe",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/クイズ（１問）-発展-クイズ（複数）.md"
        ),
    },
    {
      id: "recipe2_dog_api",
      title: "DogAPIで非同期処理",
      type: "recipe",
      time: 35,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/DogAPIで非同期処理.md"
        ),
    },
    {
      id: "recipe2_dog_api_practice_1",
      title: "【発展】DogAPIで非同期処理 - Cat or Cocktail",
      type: "recipe",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/DogAPIで非同期処理-発展-Cat or Cocktail.md"
        ),
    },
    {
      id: "recipe2_dog_api_practice_2",
      title: "【発展】DogAPIで非同期処理 - ワンちゃんきりかえボタン",
      type: "recipe",
      time: 40,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/DogAPIで非同期処理-発展-ワンちゃんきりかえボタン.md"
        ),
    },
    {
      id: "recipe2_dog_api_practice_3",
      title: "【発展】DogAPIで非同期処理 - Breeds List",
      type: "recipe",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/DogAPIで非同期処理-発展-Breeds List.md"
        ),
    },
    {
      id: "recipe2_dog_api_practice_4",
      title: "【超発展】DogAPIで非同期処理 - Promise",
      type: "recipe",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "recipe2" */ "./webex/recipe2/DogAPIで非同期処理-発展-Promise.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.web_design,
  title: "Webデザイン",
  markdowns: [
    {
      id: "web_design_prepare_portfolio_site",
      title: "ポートフォリオサイトを作ろう",
      type: "text",
      time: 20,
      asyncImport: () =>
        import(
          /* webpackChunkName: "web_design" */ "./webex/web_design/ポートフォリオサイトを作ろう.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.react,
  title: "React",
  markdowns: [
    {
      id: "react_requirements_for_learning_react",
      title: "Reactを学習する前に",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "react" */ "./webex/react/Reactを学習する前に.md"
        ),
    },
    {
      id: "react_how_to_create_react_project_with_vite",
      title: "Reactプロジェクトの作り方",
      type: "text",
      time: 10,
      asyncImport: () =>
        import(
          /* webpackChunkName: "react" */ "./webex/react/Reactプロジェクトの作り方.md"
        ),
    },
    {
      id: "react_how_to_learn_react",
      title: "Reactを学習しよう",
      type: "text",
      time: 60,
      asyncImport: () =>
        import(
          /* webpackChunkName: "react" */ "./webex/react/Reactを学習しよう.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.git,
  title: "git と GitHub",
  markdowns: [
    {
      id: "git_setup",
      title: "git の初期設定をする",
      type: "text",
      time: 10,
      asyncImport: () =>
        import(
          /* webpackChunkName: "git" */ "./webex/git/git の初期設定をする.md"
        ),
    },
    {
      id: "git_basic",
      title: "git の基本",
      type: "text",
      time: 20,
      asyncImport: () =>
        import(/* webpackChunkName: "git" */ "./webex/git/git の基本.md"),
    },
    {
      id: "git_branch",
      title: "ブランチを使って開発する",
      type: "text",
      time: 25,
      asyncImport: () =>
        import(
          /* webpackChunkName: "git" */ "./webex/git/ブランチを使って開発する.md"
        ),
    },
    {
      id: "git_github_develop",
      title: "GitとGitHubで連携して開発する",
      type: "text",
      time: 45,
      asyncImport: () =>
        import(
          /* webpackChunkName: "git" */ "./webex/git/GitとGitHubで連携して開発する.md"
        ),
    },
    {
      id: "git_create_repository",
      title: "GitHubでリポジトリをつくる",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "git" */ "./webex/git/GitHubでリポジトリをつくる.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.geek_twitter_with_react,
  title: "GeekTwitterをつくる",
  markdowns: [],
})

new Chapter({
  id: CHAPTER_ID.firebase,
  title: "Firebase",
  markdowns: [
    {
      id: "firebase_setup_with_react",
      title: "ReactアプリからFirebaseを使う",
      type: "text",
      time: 30,
      asyncImport: () =>
        import(
          /* webpackChunkName: "firebase" */ "./webex/firebase/ReactアプリからFirebaseを使う.md"
        ),
    },
    {
      id: "firebase_deploy",
      title: "Firebaseでデプロイ・削除する",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "firebase" */ "./webex/firebase/Firebaseでデプロイ・削除する.md"
        ),
    },
  ],
})

new Chapter({
  id: CHAPTER_ID.team_development,
  title: "チーム開発",
  markdowns: [
    {
      id: "how_to_make_idea",
      title: "良いアイデアの探し方",
      type: "text",
      time: 35,
      asyncImport: () =>
        import(
          /* webpackChunkName: "firebase" */ "./webex/team_development/良いアイデアの探し方.md"
        ),
    },
    {
      id: "how_to_design_application",
      title: "アプリケーションを考える",
      type: "text",
      time: 35,
      asyncImport: () =>
        import(
          /* webpackChunkName: "firebase" */ "./webex/team_development/アプリケーションを考える.md"
        ),
    },
    {
      id: "remix_project_setup",
      title: "Remix入門",
      type: "text",
      time: 15,
      asyncImport: () =>
        import(
          /* webpackChunkName: "firebase" */ "./webex/team_development/Remix入門.md"
        ),
    },
  ],
})
