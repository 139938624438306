<template>
  <div>
    <div class="MiniApp__p">
      <button @click="onClick">押してみて！</button>
    </div>
    <div
      class="MiniApp__p"
      :style="!!wastedTime && { color: 'red', fontWeight: 'bold' }"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"

const fullText =
  "じゅげむじゅげむごこうのすりきれかいじゃりすいぎょのすいぎょうまつうんらいまつふうらいまつくうねるところにすむところやぶらこうじのぶらこうじパイポパイポパイポのシューリンガンシューリンガンのグーリンダイグーリンダイのポンポコピーのポンポコナのちょうきゅうめいのちょうすけ！！！！！！！"

export default defineComponent({
  setup() {
    const length = ref(0)
    const text = ref("")
    const startedAt = Date.now()
    let wastedTime = ref(0)

    const onClick = function () {
      if (wastedTime.value) {
        return
      }
      const diff = ++length.value - fullText.length
      if (diff > 0) {
        wastedTime.value = (Date.now() - startedAt) / 1000
        text.value = `あなたはボタンのクリックに${wastedTime.value}秒を費やしました。失われた${wastedTime.value}秒はもう戻ってきません。`
      } else {
        text.value = fullText.substring(0, length.value)
      }
    }
    return { text, onClick, wastedTime }
  },
})
</script>

<style lang="scss" scoped>
.MiniApp__p {
  margin: 0.5rem;
}
</style>
