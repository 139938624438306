<template>
  <el-input
    v-model="textarea"
    type="textarea"
    :autosize="{ minRows: 16 }"
    placeholder="メモを入力してください。"
  />
  <div class="adminUserMemo__save-text-box">
    <el-icon v-if="isLoading" :size="20" class="is-loading">
      <Loading />
    </el-icon>
    <el-icon v-else :size="20">
      <Check />
    </el-icon>
    <span>{{ saveStateMessage }}</span>
  </div>
  <div
    class="adminUserMemo__save-text-box adminUserMemo__save-text-box--error"
    v-show="!!errorMessage"
  >
    {{ errorMessage }}
  </div>
</template>

<script setup>
import { updateAdminMemo, getUserAdminMemo } from "@/lib/admin"
import { computed, ref } from "vue"

import { Loading, Check } from "@element-plus/icons-vue"
import { debounce } from "throttle-debounce"
import { firebaseService } from "@/services/firebase"
const props = defineProps({
  uid: {
    type: String,
    required: true,
  },
})

const textarea = computed({
  get() {
    return getUserAdminMemo(props.uid)
  },
  set(newValue) {
    if (getUserAdminMemo(props.uid).adminMeno !== newValue) {
      updateAdminMemo(props.uid, newValue, true)
      onUpdate()
    }
  },
})

const isLoading = ref(false)
const saveStateMessage = ref("何か入力してください。")
const errorMessage = ref("")

const setUpdateAdminMemo = debounce(1000, async () => {
  try {
    await firebaseService.updateUserAdminMemo(props.uid, textarea.value)
    saveStateMessage.value = "保存しました。何か入力してください。"
  } catch (error) {
    saveStateMessage.value = "エラーが起きました。"
    errorMessage.value = error.message
  }
  isLoading.value = false
})
const onUpdate = () => {
  if (errorMessage.value) errorMessage.value = ""
  saveStateMessage.value = "変更中...(入力が止まってから1秒後に保存します。)"
  isLoading.value = true
  setUpdateAdminMemo()
}
</script>

<style lang="scss" scoped>
.adminUserMemo__save-text-box {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: flex-end;
}
.adminUserMemo__save-text-box--error {
  color: #f56c6c;
}
</style>
