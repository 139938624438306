import { computed } from "vue"
import store from "@/store"
import { article } from "@/lib/article"

export const articleUnderstading = computed(() => {
  return {
    id: article.id,
    title: article.attributes.title,
    understanding: store.getters.understanding(article.id),
  }
})

export const getUnderstanding = (articleId) => {
  return store.getters.understanding(articleId)
}
