import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
dayjs.extend(isBetween)

const today = dayjs()

export const isAfterOrSameToday = (date) => {
  return dayjs(date).isAfter(today) || dayjs(date).isSame(today)
}

export const isBetween90days = (date) => {
  const startDate = dayjs(date)
  if (!startDate) return false
  return dayjs(today).isBetween(
    startDate.subtract(2, "week"),
    startDate.add(90, "day").subtract(2, "week"),
    "day",
    "[]"
  )
}

export const formatDate = (date) => {
  return dayjs(date).format("YYYY-DD-MM HH:mm:ss")
}
